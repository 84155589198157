exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-blog-post-template-jsx-content-file-path-posts-2021-2021-11-22-neuropsychology-of-dreams-index-mdx": () => import("./../../../src/templates/blogPostTemplate.jsx?__contentFilePath=/Users/oleh/proj/demianiuk.co.ua/posts/2021/2021-11-22-neuropsychology-of-dreams/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-jsx-content-file-path-posts-2021-2021-11-22-neuropsychology-of-dreams-index-mdx" */),
  "component---src-templates-blog-post-template-jsx-content-file-path-posts-2021-2021-11-22-therapy-rules-index-mdx": () => import("./../../../src/templates/blogPostTemplate.jsx?__contentFilePath=/Users/oleh/proj/demianiuk.co.ua/posts/2021/2021-11-22-therapy-rules/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-jsx-content-file-path-posts-2021-2021-11-22-therapy-rules-index-mdx" */),
  "component---src-templates-blog-post-template-jsx-content-file-path-posts-2025-2025-03-29-how-ability-to-play-influences-index-mdx": () => import("./../../../src/templates/blogPostTemplate.jsx?__contentFilePath=/Users/oleh/proj/demianiuk.co.ua/posts/2025/2025-03-29-how-ability-to-play-influences/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-jsx-content-file-path-posts-2025-2025-03-29-how-ability-to-play-influences-index-mdx" */),
  "component---src-templates-blog-post-template-jsx-content-file-path-posts-2025-2025-04-08-grief-or-depression-index-mdx": () => import("./../../../src/templates/blogPostTemplate.jsx?__contentFilePath=/Users/oleh/proj/demianiuk.co.ua/posts/2025/2025-04-08-grief-or-depression/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-jsx-content-file-path-posts-2025-2025-04-08-grief-or-depression-index-mdx" */)
}

